import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Services = (props) => {
  const activities = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-services">
      <SEO title="Services" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className='col-lg-4 col-3'></div>
            <div className="col-lg-8 col-9">
              <h1>Activities</h1>
              <p>Take the learning and fun home with our downloadable activities!</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-6 pb-6">
        <div className="row">
          {activities.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <Link to={edge.node.frontmatter.path}>
                    <img src={edge.node.frontmatter.image} data-pin-nopin="true" />
                    <h2 style={{
                      width: '100%',
                      height: '40px',
                      lineHeight:'44px',
                      textAlign:'center',
                      backgroundColor:'#119cbdb5'
                    }}>{edge.node.frontmatter.title}</h2>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/activities/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
            image
          }
        }
      }
    }
  }
`;

export default Services;
